import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Layout from "./components/layout/Layout";

import Login from "./components/Login";
import Profile from "./components/Profile";

import PembayaranRoot from "./components/pembayaran/PembayaranRoot";
import PembayaranIndex from "./components/pembayaran/PembayaranIndex";

import PayBatalRoot from "./components/paybatal/PayBatalRoot";
import PayBatalIndex from "./components/paybatal/PayBatalIndex";

import ErrorPage from "./error-page";
import EppsiIndex from "./components/eppsi/EppsiIndex";
import EppsiRoot from "./components/eppsi/EppsiRoot";
import MutasiIndex from "./components/mutasi/MutasiIndex";
import MutasiRoot from "./components/mutasi/MutasiRoot";
import HomeIndex from "./components/home/HomeIndex";
import PendidikanRoot from "./components/pendidikan/PendidikanRoot";
import PendidikanIndex from "./components/pendidikan/PendidikanIndex";

import TesIndex from "./components/tes/TesIndex";
import TesRoot from "./components/tes/TesRoot";

import TotalPrintIndex from "./components/totalprint/TotalPrintIndex";
import TotalPrintRoot from "./components/totalprint/TotalPrintRoot";
import DataTotalPrint from "./components/totalprint/DataTotalPrint";

import TglReportIndex from "./components/tglReport/TanggalReportIndex";
import GenerateIndex from "./components/tglReport/GenerateIndex";

import TglReportRoot from "./components/tglReport/TglReportRoot";
import TglReportAdd from "./components/tglReport/TglReportAdd";
import TglReportEdit from "./components/tglReport/TglReportEdit";

import UpdateNilaiRoot from "./components/updateNilai/UpdateNilaiRoot";
import UpdateNilaiIndex from "./components/updateNilai/UpdateNilaiIndex";

import TotalKoselingRoot from "./components/totalKonseling/TotalKonselingRoot";
import TotalKonselingIndex from "./components/totalKonseling/TotalKonselingIndex";

// Root Arsip
import PembayaranArsipRoot from "./components/pembayaranArsip/PembayaranArsipRoot";
import PembayaranArsipIndex from "./components/pembayaranArsip/PembayaranArsipIndex";

import TesArsipIndex from "./components/tesArsip/TesArsipIndex";
import TesArsipRoot from "./components/tesArsip/TesArsipRoot";

import TotalPrintArsipIndex from "./components/totalPrintArsip/TotalPrintArsipIndex";
import TotalPrintArsipRoot from "./components/totalPrintArsip/TotalPrintArsipRoot";
import DataTotalPrintArsip from "./components/totalPrintArsip/DataTotalPrintArsip";

// report print
import ReportPrintIndex from "./components/reportPrint/ReportPrintIndex";
import ReportPrintRoot from "./components/reportPrint/ReportPrintRoot";

import RekapPemohonTesRoot from "./components/rekapPemohonTes/RekapPemohonTesRoot";
import RekapPemohonTesIndex from "./components/rekapPemohonTes/RekapPemohonTesIndex";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        children: [
          {
            index: true,
            element: <HomeIndex />,
          },
        ],
      },
      {
        path: "pembayaran",
        element: <PembayaranRoot />,
        children: [
          {
            index: true,
            element: <PembayaranIndex />,
          },
        ],
      },
      {
        path: "pembayaran-arsip",
        element: <PembayaranArsipRoot />,
        children: [
          {
            index: true,
            element: <PembayaranArsipIndex />,
          },
        ],
      },
      {
        path: "paybatal",
        element: <PayBatalRoot />,
        children: [
          {
            index: true,
            element: <PayBatalIndex />,
          },
        ],
      },
      {
        path: "doceppsi",
        element: <EppsiRoot />,
        children: [
          {
            index: true,
            element: <EppsiIndex />,
          },
        ],
      },
      {
        path: "mutasi",
        element: <MutasiRoot />,
        children: [
          {
            index: true,
            element: <MutasiIndex />,
          },
        ],
      },
      {
        path: "laporan-pendidikan",
        element: <PendidikanRoot />,
        children: [
          {
            index: true,
            element: <PendidikanIndex />,
          },
        ],
      },
      {
        path: "laporan-tes",
        element: <TesRoot />,
        children: [
          {
            index: true,
            element: <TesIndex />,
          },
        ],
      },
      {
        path: "laporan-tes-arsip",
        element: <TesArsipRoot />,
        children: [
          {
            index: true,
            element: <TesArsipIndex />,
          },
        ],
      },
      {
        path: "total-print",
        element: <TotalPrintRoot />,
        children: [
          {
            index: true,
            element: <TotalPrintIndex />,
          },
          {
            path: ":detail",
            element: <DataTotalPrint />,
          },
        ],
      },
      {
        path: "tanggal-report",
        element: <TglReportRoot />,
        children: [
          {
            index: true,
            element: <TglReportIndex />,
          },
          {
            path: "data-generate",
            element: <GenerateIndex />,
          },
          {
            path: ":id",
            element: <TglReportEdit />,
          },
          {
            path: "add",
            element: <TglReportAdd />,
          },
        ],
      },
      {
        path: "update-nilai",
        element: <UpdateNilaiRoot />,
        children: [
          {
            index: true,
            element: <UpdateNilaiIndex />,
          },
        ],
      },
      {
        path: "rekap-pemohon-tes",
        element: <RekapPemohonTesRoot />,
        children: [
          {
            index: true,
            element: <RekapPemohonTesIndex />,
          },
        ],
      },
      {
        path: "total-konseling",
        element: <TotalKoselingRoot />,
        children: [
          {
            index: true,
            element: <TotalKonselingIndex />,
          },
        ],
      },
      {
        path: "report-print",
        element: <ReportPrintRoot />,
        children: [
          {
            index: true,
            element: <ReportPrintIndex />,
          },
        ],
      },
      {
        path: "total-print-arsip",
        element: <TotalPrintArsipRoot />,
        children: [
          {
            index: true,
            element: <TotalPrintArsipIndex />,
          },
          {
            path: ":detail",
            element: <DataTotalPrintArsip />,
          },
        ],
      },
      {
        path: "profile",
        element: <Profile />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
