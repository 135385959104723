/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import {
  FaSync,
  FaSearch,
  FaTrash,
  FaFileExcel,
  FaCog,
  FaHandPointUp,
} from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";

const RekapPemohonTesIndex = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [showSet, setShowSet] = useState(false);

  const navigate = useNavigate();

  const [payment, setPayment] = useState([]);
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);

  const [tglMulai, setTglMulai] = useState("");
  const [tglAkhir, setTglAkhir] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const btnLoading = isLoading ? "is-loading" : "";

  const [isDownload, setIsDownload] = useState(false);
  const btnDownload = isDownload ? "is-loading" : "";

  /* eslint-disable-next-line */
  const [limit, setLimit] = useState(10);
  const [customLimit, setCustomLimit] = useState("");
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [dateFirst, setDateFirst] = useState("");
  const [dateSecond, setDateSecond] = useState("");

  const [keyword, setKeyword] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    refreshToken();
    getAllPembayaran();
  }, [page, keyword, dateFirst, dateSecond]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllPembayaran = async () => {
    setIsLoading(true);
    await axiosJWT
      .get(
        `https://srvtes.psisim.net/admin/laporan-pemohon-tes?tglFirst=${dateFirst}&tglSecond=${dateSecond}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setShowSet(false);
        setPayment(response.data.docs);
        setPage(response.data.page);
        setPages(response.data.totalPages);
        setRows(response.data.totalDocs);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const downloadCsv = async () => {
    setIsDownload(true);
    await axiosJWT
      .get(
        `https://srvtes.psisim.net/admin/laporan-pemohon-tes-csv?tglFirst=${dateFirst}&tglSecond=${dateSecond}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        axios
          .get(`https://srvtes.psisim.net/csv-file/${response.data.data}`, {
            responseType: "blob",
            headers: {
              "Content-Disposition": "attachment;filename=report.xls",
              "Content-Type": "text/csv",
            },
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "report.csv");
            document.body.appendChild(link);
            link.click();

            setIsDownload(false);
            Swal.fire({
              icon: "success",
              title: "Success Download",
              text: "Berhasil mendownload csv",
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          });
      })
      .catch((error) => {
        setIsDownload(false);
        Swal.fire({
          icon: "error",
          title: "Error Download",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      });
  };

  const changePage = ({ selected }) => {
    setPage(selected);
    if (selected === 9) {
      setMsg(
        "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
      );
    } else {
      setMsg("");
    }
  };

  const convertDate = (date) => {
    let dateConv = new Date(date);
    var milliseconds = dateConv.getTime();

    return milliseconds;
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setShow(true);

    setDateFirst(convertDate(tglMulai));
    setDateSecond(convertDate(tglAkhir));
  };

  return (
    <>
      <form onSubmit={searchData} className="mt-10">
        <div className="columns is-desktop">
          <div className="column is-narrow">
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Tanggal</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="datetime-local"
                      className="input is-small"
                      value={tglMulai}
                      onChange={(e) => setTglMulai(e.target.value)}
                      placeholder="0000-00-00 00:00:00"
                    />
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="datetime-local"
                      className="input is-small"
                      value={tglAkhir}
                      min={tglMulai}
                      onChange={(e) => setTglAkhir(e.target.value)}
                      placeholder="0000-00-00 00:00:00"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="buttons is-fullwidth is-centered has-background-light pt-2">
          <button
            type="submit"
            className={`button is-info is-small is-rounded ${btnLoading}`}
          >
            <span className="icon">
              <FaSearch />
            </span>
            <span>Filter Data</span>
          </button>
          <button
            type="button"
            onClick={() => downloadCsv()}
            className={`button is-success is-light is-rounded is-small ${btnDownload}`}
          >
            <span className="icon">
              <FaFileExcel />
            </span>
            <span className="is-hidden-mobile">Download CSV</span>
          </button>
          <a
            href="/pembayaran"
            className="button is-link is-small is-rounded is-light is-right"
          >
            <span className="icon">
              <FaSync />
            </span>
            <span className="is-hidden-mobile">Refresh</span>
          </a>
        </p>
      </form>

      {show ? (
        <>
          {payment ? (
            <>
              <div className="table-container">
                <div className="field is-horizontal mt-6 mb-4 ml-4">
                  <div className="columns is-desktop">
                    <div className="field-label is-small">
                      <label className="label">Limit Data</label>
                    </div>
                    <div className="field-body">
                      <div
                        className="field"
                        style={{
                          marginLeft: "0",
                          paddingLeft: "0",
                          textAlign: "left",
                        }}
                      >
                        <div className="control">
                          <div className="select is-small">
                            <select
                              value={limit}
                              onChange={(e) => setLimit(e.target.value)}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="1000">1000</option>
                              <option value="1000000">1000000</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <table className="table is-striped is-bordered is-fullwidth is-hoverable is-size-7">
                  <thead className="table-header">
                    <tr>
                      <th>No.</th>
                      <th>Tgl.Tes</th>
                      <th>No.Tes</th>
                      <th>NIK</th>
                      <th>Nama</th>
                      <th>Alamat</th>
                      <th>Jen. SIM</th>
                      <th>Kat.Sim</th>
                      <th>Pendidikan</th>
                      <th>Lulus</th>
                      <th>Transaksi</th>
                      <th>Tgl.Transaksi</th>
                      <th>No.Transaksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payment.map((payment, index) => (
                      <tr key={index}>
                        <td>{payment.no}</td>
                        <td>{payment.tglTes}</td>
                        <td>{payment.nomorTes}</td>
                        <td>{payment.nik}</td>
                        <td>{payment.namaLengkap}</td>
                        <td>{payment.alamat}</td>
                        <td>{payment.jenSim}</td>
                        <td>{payment.katSim}</td>
                        <td>{payment.pdTerakhir}</td>
                        <td
                          className={
                            payment.hasilTes === "MS"
                              ? "has-text-success"
                              : "has-text-danger"
                          }
                        >
                          {payment.hasilTes}
                        </td>
                        <td
                          className={
                            payment.transaksi === "YA"
                              ? "has-text-success"
                              : "has-text-danger"
                          }
                        >
                          {payment.transaksi}
                        </td>
                        <td>{payment.tglTransaksi}</td>
                        <td>{payment.noTransaksi}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className="has-text-centered has-text-danger">{msg}</p>
              <div className="columns">
                <div className="column">
                  <span className="is-size-7">
                    Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}
                  </span>
                </div>
                <div className="column">
                  <nav
                    className="pagination is-rounded is-small is-right"
                    key={rows}
                    role="navigation"
                    aria-label="pagination"
                  >
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      pageCount={Math.min(10, pages)}
                      onPageChange={changePage}
                      containerClassName={"pagination-list"}
                      pageLinkClassName={"pagination-link"}
                      previousLinkClassName={"pagination-previous"}
                      nextLinkClassName={"pagination-next"}
                      activeLinkClassName={"pagination-link is-current"}
                      disabledLinkClassName={"pagination-link is-disabled"}
                    />
                  </nav>
                </div>
              </div>
            </>
          ) : (
            <div className="is-fullwidth has-text-centered mt-5">
              <figure className="image is-2by2 is-inline-block">
                <img
                  src="https://plan.literasikitaindonesia.com/kasir/no-data-found.png"
                  alt="logo cart"
                />
              </figure>
              <h4>Data tidak ditemukan</h4>
            </div>
          )}
        </>
      ) : (
        <div className="is-fullwidth has-text-centered mt-5">
          <img
            src="https://plan.literasikitaindonesia.com/psisim/please-filter2.png"
            alt="logo cart"
            width={380}
          />
          <h4>Silahkan filter data terlebih dahulu</h4>
        </div>
      )}
    </>
  );
};

export default RekapPemohonTesIndex;
